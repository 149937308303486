<template>
    <div>

    </div>
</template>
<script>
export default {
    mounted() {
        if(document.body.clientWidth > 500) {
            this.$router.push('/home')
        }else {
            this.$router.push('/mobile')
        }
    }
}
</script>

